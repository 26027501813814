@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Avenir;
    src: url(../public/Avenir-Regular.ttf);
  }

  html {
    font-family: 'Avenir', 'Work Sans', sans-serif !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #B484DE; 
  border-radius: 7px 0px 0px 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #A033FF; 
}

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.boxShadowHeader{
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
}
body {
  box-sizing: border-box;
  line-height:1;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.image-container {
  position: relative; /* Ensure the container is positioned */
  width: 100%; /* Set width to 100% */
  height: 400px; /* Set a fixed height for the container */
}

.fade-in-image {
  position: absolute; /* Position the image absolutely within the container */
  top: 0; /* Align the image to the top of the container */
  left: 0; /* Align the image to the left of the container */
  opacity: 0; /* Initially set opacity to 0 */
  transform: translateY(-20px); /* Initially move the image up */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Apply transitions */
}

.fade-in-image.selected {
  opacity: 1; /* Set opacity to 1 to make it visible */
  transform: translateY(0); /* Move the image to its original position */
}

